@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@800;900&family=Montserrat:wght@300;400;600&display=swap');

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

:root {
  --primary: #2A2A72;
  --secondary: #009FFD;
  --accent: #FF3366;
  --dark: #0A0A1F;
  --light: #FFFFFF;
  --gradient: linear-gradient(135deg, var(--primary), var(--secondary));
}

body {
  margin: 0;
  padding: 0;
  background: var(--dark);
  font-family: 'Montserrat', sans-serif;
  color: var(--light);
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  cursor: none;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

/* Subtle animated background */
.app::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 0% 0%, rgba(42, 42, 114, 0.1) 0%, transparent 50%),
    radial-gradient(circle at 100% 100%, rgba(0, 159, 253, 0.15) 0%, transparent 50%);
  animation: subtlePulse 15s ease-in-out infinite alternate;
  z-index: -1;
}

@keyframes subtlePulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.5);
    opacity: 1;
  }
}

.app {
  text-align: center;
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero {
  margin: 2rem 0 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  opacity: 0;
  animation: fadeInSlideUp 1s ease-out forwards;
  width: 100%;
}

@keyframes fadeInSlideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.title {
  font-family: 'Orbitron', sans-serif;
  font-weight: 900;
  font-size: 3.5rem;
  margin: 0;
  color: var(--light);
  letter-spacing: 2px;
  position: relative;
  text-transform: uppercase;
  background: linear-gradient(to right, 
    #fff 0%,
    #f0f0f0 29%,
    #009FFD 67%,
    #2A2A72 100%
  );
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: shine 8s linear infinite;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1;
  padding-bottom: 15px;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 3px;
  background: linear-gradient(
    90deg,
    var(--secondary),
    var(--accent),
    var(--secondary)
  );
  background-size: 200% 100%;
  animation: 
    lineExpand 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards,
    gradientMove 3s linear infinite;
  border-radius: 4px;
  box-shadow: 
    0 0 10px rgba(0, 159, 253, 0.3),
    0 0 20px rgba(255, 51, 102, 0.2);
}

@keyframes lineExpand {
  0% {
    width: 0;
    opacity: 0;
  }
  50% {
    width: 80%;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

@keyframes gradientMove {
  0% {
    background-position: 100% 0;
  }
  50% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}

/* Add hover effect */
.title:hover::after {
  animation: 
    gradientMove 1.5s linear infinite,
    lineGlow 2s ease-in-out infinite;
}

@keyframes lineGlow {
  0% {
    box-shadow: 
      0 0 10px rgba(0, 159, 253, 0.3),
      0 0 20px rgba(255, 51, 102, 0.2);
  }
  50% {
    box-shadow: 
      0 0 15px rgba(0, 159, 253, 0.5),
      0 0 30px rgba(255, 51, 102, 0.3);
  }
  100% {
    box-shadow: 
      0 0 10px rgba(0, 159, 253, 0.3),
      0 0 20px rgba(255, 51, 102, 0.2);
  }
}

.neon-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 300;
  color: var(--secondary);
  margin: 1rem 0 0;
  letter-spacing: 6px;
  opacity: 0;
  animation: fadeIn 1s ease-out 0.8s forwards;
  text-transform: uppercase;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.location-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--accent);
  margin: -0.5rem 0 0;
  letter-spacing: 2px;
  opacity: 0;
  animation: fadeIn 1s ease-out 1s forwards;
}

.social-links {
  display: flex;
  gap: 1.5rem;
  margin: 2rem 0;
  opacity: 0;
  animation: fadeIn 1s ease-out 1s forwards;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.social-button {
  padding: 0.8rem 2rem;
  min-width: 140px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1),
              transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform-origin: center;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.social-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.social-button:hover::before {
  transform: translateX(100%);
}

.social-button::after {
  content: '';
  position: absolute;
  inset: -2px;
  border-radius: inherit;
  background: linear-gradient(
    45deg,
    transparent 50%,
    rgba(255, 255, 255, 0.1) 60%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.social-button:hover::after {
  opacity: 1;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

.instagram {
  color: #E1306C;
}

.facebook {
  color: #1877F2;
}

.ticket {
  color: var(--accent);
}

.social-button:hover {
  transform: translateY(-2px) scale(1.02);
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.social-button:active {
  transform: scale(0.95) translateY(2px);
  transition: transform 0.1s;
}

.flyer-section {
  margin: 2rem auto;
  position: relative;
  max-width: 1000px;
  padding: 1rem;
  opacity: 0;
  animation: fadeInSlideUp 1s ease-out 0.5s forwards;
  perspective: 1000px;
  width: 90%;
}

.event-flyer {
  width: 100%;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.4);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transform: perspective(1000px) rotateX(5deg);
  will-change: transform;
  min-height: 300px;
  object-fit: contain;
}

.event-flyer:hover {
  transform: perspective(1000px) rotateX(0deg) translateY(-5px);
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.4);
}

@media (max-width: 1024px) {
  .app {
    flex-direction: column;
    padding: 1rem;
    text-align: center;
  }

  .hero {
    width: 100%;
    align-items: center;
    order: 1;
  }

  .flyer-section {
    width: 95%;
    order: 2;
  }

  .social-links {
    justify-content: center;
  }
}

/* Add specific styles for very small screens */
@media (max-width: 360px) {
  .title {
    font-size: 2rem;
  }
  
  .neon-subtitle {
    font-size: 0.8rem;
  }

  .social-links {
    gap: 0.4rem;
  }

  .social-button {
    min-width: 90px;
    padding: 0.5rem 0.8rem;
    font-size: 0.75rem;
  }

  .social-button svg {
    font-size: 0.9rem;
  }

  .location-text {
    font-size: 0.8rem;
  }
}

/* Add cursor effect styles */
.cursor-effect {
  position: fixed;
  width: 20px;
  height: 20px;
  border: 2px solid var(--secondary);
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: width 0.2s, height 0.2s;
  z-index: 9999;
  mix-blend-mode: difference;
  display: none;
  box-shadow: 0 0 15px var(--secondary);
  mix-blend-mode: screen;
  animation: cursorPulse 2s infinite;
}

@keyframes cursorPulse {
  0% { transform: translate(-50%, -50%) scale(1); opacity: 0.8; }
  50% { transform: translate(-50%, -50%) scale(1.2); opacity: 0.5; }
  100% { transform: translate(-50%, -50%) scale(1); opacity: 0.8; }
}

/* Add these new interactive effects */
.interactive-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

.glow {
  position: absolute;
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, 
    rgba(0, 159, 253, 0.15) 0%,
    transparent 70%);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Add touch ripple effect */
.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 0.6s linear;
  background-color: rgba(255, 255, 255, 0.2);
  background: radial-gradient(circle, 
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.3) 40%,
    transparent 70%
  );
  mix-blend-mode: screen;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

/* Add these new animations */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Hover trail effect */
.trail {
  position: fixed;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--secondary);
  pointer-events: none;
  opacity: 0;
  z-index: 9998;
}

/* Update these title-related styles */

.title {
  /* Keep existing styles */
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center;
}

/* Replace the glitch effect with a smoother hover animation */
.title:hover {
  animation: none; /* Remove the glitch animation */
  transform: scale(1.02) translateY(-2px);
  cursor: pointer;
}

/* Remove these conflicting hover styles */
.title:hover:before,
.title:hover:after {
  display: none;
}

/* Update the underline animation to be smoother */
.title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 3px;
  background: linear-gradient(
    90deg,
    var(--secondary),
    var(--accent),
    var(--secondary)
  );
  background-size: 200% 100%;
  animation: 
    lineExpand 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards,
    gradientMove 3s linear infinite;
  border-radius: 4px;
  box-shadow: 
    0 0 10px rgba(0, 159, 253, 0.3),
    0 0 20px rgba(255, 51, 102, 0.2);
}

/* Remove any other glitch-related animations */
@keyframes glitch {
  /* Remove this animation */
}

/* Enhanced button hover effects */
.social-button {
  /* Add to existing social-button styles */
  position: relative;
  z-index: 1;
}

.social-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, 
    rgba(255, 255, 255, 0.1) 0%,
    transparent 70%);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: -1;
}

.social-button:hover:before {
  opacity: 1;
  animation: pulseLight 2s infinite;
}

@keyframes pulseLight {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

/* Magnetic effect for buttons */
.social-button:hover {
  transform: translate(var(--moveX, 0), var(--moveY, 0)) scale(1.05);
}

/* Enhanced flyer hover effect */
.event-flyer {
  /* Add to existing event-flyer styles */
  filter: brightness(0.9) contrast(1.1);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1), filter 0.3s ease;
}

.event-flyer:hover {
  filter: brightness(1.1) contrast(1.2);
  transform: perspective(1000px) rotateX(5deg) rotateY(5deg) translateZ(20px);
  box-shadow: 
    0 30px 50px rgba(0, 0, 0, 0.4),
    0 0 30px rgba(0, 159, 253, 0.3);
}

/* Particle effect for background */
.particle {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  background: radial-gradient(circle, var(--secondary) 0%, transparent 70%);
  animation: particleFade 3s ease-out forwards;
}

@keyframes particleFade {
  0% {
    opacity: 0.5;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

/* Enhanced location text */
.location-text {
  /* Add to existing location-text styles */
  position: relative;
  display: inline-block;
}

.location-text:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--accent);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.location-text:hover:after {
  transform: scaleX(1);
  transform-origin: left;
}

/* Update these media query sections in your index.css */

@media (max-width: 768px) {
  .app {
    padding: 1rem 0.5rem;
  }

  .hero {
    margin: 1rem 0 1.5rem;
    gap: 1rem;
  }

  .title {
    font-size: 2.2rem;
    letter-spacing: 1px;
    padding-bottom: 10px;
  }
  
  .neon-subtitle {
    font-size: 0.9rem;
    letter-spacing: 3px;
    margin-top: 0.5rem;
  }

  .location-text {
    font-size: 0.9rem;
    margin-top: 0.2rem;
  }

  .social-links {
    flex-direction: row;
    align-items: center;
    gap: 0.6rem;
    margin: 1rem 0;
    padding: 0 0.5rem;
  }

  .social-button {
    width: auto;
    min-width: 100px;
    padding: 0.6rem 1rem;
    font-size: 0.8rem;
  }

  .flyer-section {
    margin: 1rem auto;
    padding: 0 0.5rem;
  }

  .event-flyer {
    border-radius: 12px;
    min-height: auto;
  }

  .title::after {
    height: 2px;
  }
}

/* Smaller phones */
@media (max-width: 360px) {
  .title {
    font-size: 1.8rem;
    letter-spacing: 0.5px;
    padding-bottom: 8px;
  }
  
  .neon-subtitle {
    font-size: 0.8rem;
    letter-spacing: 2px;
  }

  .location-text {
    font-size: 0.8rem;
  }

  .social-button {
    min-width: 90px;
    padding: 0.5rem 0.8rem;
    font-size: 0.75rem;
  }

  .social-button svg {
    font-size: 0.9rem;
  }

  .title::after {
    height: 2px;
  }
}
